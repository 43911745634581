import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import { ThemeProvider } from "@material-ui/styles";
import styled from "styled-components";
import Mailto from "react-protected-mailto";
import "react-vis/dist/style.css";
import {
  FlexibleWidthXYPlot,
  LineSeries,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  DiscreteColorLegend
} from "react-vis";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  BooleanParam,
  ArrayParam
} from "use-query-params";
import isEqual from "lodash.isequal";

import { calcChargingTimes, getChargerList } from "../components/range";

const isBrowser = () => typeof window !== "undefined";

const palette = [
  "#e6194b",
  "#3cb44b",
  //"#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  //"#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080"
];

const theme = responsiveFontSizes(createMuiTheme({}));

const LargeSelect = styled(Select)`
  font-size: inherit;
`;

const StopTimeSelector = ({ stopTime, setStopTime }) => (
  <LargeSelect
    value={stopTime}
    onChange={event => setStopTime(+event.target.value)}
    autoWidth
  >
    <MenuItem value={5}>5 minutes</MenuItem>
    <MenuItem value={10}>10 minutes</MenuItem>
    <MenuItem value={15}>15 minutes</MenuItem>
    <MenuItem value={20}>20 minutes</MenuItem>
    <MenuItem value={25}>25 minutes</MenuItem>
    <MenuItem value={30}>30 minutes</MenuItem>
    <MenuItem value={35}>35 minutes</MenuItem>
    <MenuItem value={40}>40 minutes</MenuItem>
    <MenuItem value={45}>45 minutes</MenuItem>
    <MenuItem value={50}>50 minutes</MenuItem>
    <MenuItem value={55}>55 minutes</MenuItem>
    <MenuItem value={60}>1 hour</MenuItem>
  </LargeSelect>
);

const TravelTimeSelector = ({ travelTime, setTravelTime }) => (
  <LargeSelect
    value={travelTime}
    onChange={event => setTravelTime(+event.target.value)}
    autoWidth
  >
    <MenuItem value={30}>30 minutes</MenuItem>
    <MenuItem value={45}>45 minutes</MenuItem>
    <MenuItem value={60}>1 hour</MenuItem>
    <MenuItem value={75}>1 hour 15 min</MenuItem>
    <MenuItem value={90}>1 hour 30 min</MenuItem>
    <MenuItem value={105}>1 hour 45 min</MenuItem>
    <MenuItem value={120}>2 hours</MenuItem>
    <MenuItem value={150}>2 hours 30 min</MenuItem>
    <MenuItem value={180}>3 hours</MenuItem>
    <MenuItem value={210}>3 hours 30 min</MenuItem>
    <MenuItem value={240}>4 hours</MenuItem>
  </LargeSelect>
);

const Yes = styled(Typography)`
  color: green;
  font-size: 10em;
  font-weight: bold;
`;

const No = styled(Typography)`
  color: red;
  font-size: 8em;
`;

const YesNo = ({ yes }) =>
  yes ? (
    <Yes variant="h1" align="center" gutterBottom>
      YES
    </Yes>
  ) : (
    <No variant="h1" align="center" gutterBottom>
      NOT YET
    </No>
  );

const GoodCell = styled(TableCell)`
  font-weight: bold;
  background-color: #d6fcd6;
`;

const BadCell = styled(TableCell)`
  background-color: #feeeaf;
`;

const ImpossibleCell = styled(TableCell)`
  text-decoration: line-through;
  background-color: #ffc9c9;
`;

const DimText = styled.span`
  color: #6e6e6e;
  font-size: 80%;
  white-space: nowrap;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const ChargingTimes = ({ chargingTimes, showTrims, selected, setSelected }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Vehicle</TableCell>
        <TableCell>Time</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {chargingTimes.map(
        ({ id, vehicle, charger, chargingTime, good, impossible }, index) => {
          const CellClass = good
            ? GoodCell
            : impossible
            ? ImpossibleCell
            : BadCell;
          return (
            <TableRow key={index}>
              <CellClass>
                <Tooltip title={vehicle.description}>
                  <span>
                    {vehicle.make} {vehicle.model}{" "}
                    {showTrims ? <>{vehicle.trim}</> : <></>}
                  </span>
                </Tooltip>
                <Tooltip title={charger.description}>
                  <DimText> ({charger.shortname})</DimText>
                </Tooltip>
              </CellClass>
              <CellClass>
                <NoWrap>
                  {chargingTime === Infinity ? (
                    <>∞</>
                  ) : (
                    <>{Math.round(chargingTime)} min</>
                  )}
                </NoWrap>
              </CellClass>
            </TableRow>
          );
        }
      )}
    </TableBody>
  </Table>
);

const ChargerSelector = ({
  selectedChargers,
  setSelectedChargers,
  chargerList
}) => (
  <Select
    multiple
    value={selectedChargers}
    onChange={event => setSelectedChargers(event.target.value.sort())}
    renderValue={selected => `Chargers (${selected.length})`}
    autoWidth
  >
    {chargerList.map(x => (
      <MenuItem key={x.id} value={x.id}>
        <Checkbox checked={selectedChargers.indexOf(x.id) > -1} />
        <ListItemText primary={x.name} />
      </MenuItem>
    ))}
  </Select>
);

const ChartSelector = ({ chart, setChart, chartChoices }) => (
  <Box component="span" mx={2}>
    <Typography variant="body1" component="span" noWrap>
      Chart:{" "}
      <Select
        value={chart}
        onChange={event => setChart(event.target.value)}
        autoWidth
      >
        {Object.entries(chartChoices).map(([k, v]) => (
          <MenuItem key={k} value={k}>
            {v.short}
          </MenuItem>
        ))}
      </Select>
    </Typography>
  </Box>
);

const Question = ({ title, children }) => (
  <>
    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>
    <Box ml={4}>
      <Typography component="p" paragraph>
        {children}
      </Typography>
    </Box>
  </>
);

const Faq = ({ children }) => <Container maxWidth="md">{children}</Container>;

const useParam = (name, type, defval) => {
  if (!isBrowser()) return [defval, () => undefined];
  const [updateCount, setUpdateCount] = useState(0);
  const [rawValue, rawSetter] = useQueryParam(name, type);
  const value = rawValue === undefined ? defval : rawValue;
  const setter = val => {
    setUpdateCount(updateCount + 1);
    isEqual(val, defval) ? rawSetter(undefined) : rawSetter(val);
  };
  return [value, setter];
};

const IndexPage = () => {
  const chargerList = getChargerList();
  const [stopTime, setStopTime] = useParam("s", NumberParam, 15);
  const [travelTime, setTravelTime] = useParam("t", NumberParam, 120);

  const [showChargers, setShowChargers] = useParam("ac", BooleanParam, false);
  const [showTrims, setShowTrims] = useParam("at", BooleanParam, false);
  const [selectedChargers, setSelectedChargers] = useParam(
    "c",
    ArrayParam,
    chargerList.map(x => x.id).sort()
  );
  const [selected, setSelected] = useParam("sel", StringParam, "");
  const [chart, setChart] = useParam("chart", StringParam, "km");
  const chartYTitles = {
    km: { title: "Range in km @ 110 km/h", short: "Range (km)" },
    mile: { title: "Range in miles @ 68 mph", short: "Range (miles)" },
    kwh: { title: "Charged energy in kWh", short: "Energy (kWh)" },
    kw: { title: "Charging power in kW", short: "Power (kW)" },
    soc: { title: "State of charge in %", short: "SoC (%)" }
  };
  const chartYTick = {
    km: (110 * travelTime) / 60,
    mile: (110 * travelTime) / 60 / 1.60934
  };

  const seen = {};
  const chargingTimes = calcChargingTimes({ stopTime, travelTime }).filter(
    x => {
      if (x.vehicle.hide) return false;
      if (selectedChargers.indexOf(x.charger.id) === -1) return false;
      const key = `${x.vehicle.make} ${x.vehicle.model} ${
        showTrims ? x.vehicle.trim : ""
      } ${showChargers ? x.charger.id : ""}`;
      return seen[key] ? false : (seen[key] = true);
    }
  );
  const good = (chargingTimes[0] || { good: false }).good;
  const graphStash = [];

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Can EVs Road Trip?"
          meta={[
            {
              name: "description",
              content:
                "Exploring electric vehicle charging speeds for road trips. Allows comparing different EV models with their charging times."
            },
            {
              name: "keywords",
              content:
                "ev, electric vehicle, electric car, charging, vehicle, car, roadtrip"
            }
          ]}
        />
        <CssBaseline />
        <main>
          <Box my={5}>
            <Typography variant="h5" align="center" gutterBottom>
              <span style={{ display: "inline-block" }}>
                I take a break for{" "}
                <StopTimeSelector
                  stopTime={stopTime}
                  setStopTime={setStopTime}
                />{" "}
              </span>
              <span style={{ display: "inline-block" }}>
                every{" "}
                <TravelTimeSelector
                  travelTime={travelTime}
                  setTravelTime={setTravelTime}
                />
              </span>
            </Typography>
          </Box>
          <Container maxWidth="md">
            <Typography variant="h1" align="center" gutterBottom>
              Can EVs road trip?
            </Typography>
            <YesNo yes={good} />
            <ChargingTimes
              chargingTimes={chargingTimes}
              showTrims={showTrims}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography variant="body1" component="div" align="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!showChargers}
                    onChange={() => setShowChargers(!showChargers)}
                    color="primary"
                  />
                }
                label="Only best charger"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!showTrims}
                    onChange={() => setShowTrims(!showTrims)}
                    color="primary"
                  />
                }
                label="Only best trim"
              />
              <ChargerSelector
                selectedChargers={selectedChargers}
                setSelectedChargers={setSelectedChargers}
                chargerList={chargerList}
              />
              <ChartSelector
                chart={chart}
                setChart={setChart}
                chartChoices={chartYTitles}
              />
            </Typography>
          </Container>

          <Container maxWidth="md">
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item md xs={12}>
                    <FlexibleWidthXYPlot height={450} xDomain={[0, 60]}>
                      <HorizontalGridLines animation />
                      <VerticalGridLines animation />
                      <XAxis title="charging time in minutes" animation />
                      <YAxis title={chartYTitles[chart].title} animation />
                      <VerticalGridLines
                        tickValues={[stopTime]}
                        style={{ stroke: "#b7b4bd", strokeWidth: 1 }}
                      />
                      {chargingTimes
                        .map((x, i) =>
                          selected === x.id ? (
                            graphStash.push(
                              <LineSeries
                                key={x.id}
                                data={x[`${chart}Points`]}
                                strokeWidth={4}
                                onSeriesClick={e => setSelected(x.id)}
                                stroke={palette[i % palette.length]}
                                animation
                              />
                            ) && undefined
                          ) : (
                            <LineSeries
                              key={x.id}
                              data={x[`${chart}Points`]}
                              strokeWidth={2}
                              onSeriesClick={e => setSelected(x.id)}
                              stroke={palette[i % palette.length]}
                              animation
                            />
                          )
                        )
                        .reverse()}
                      {graphStash}
                      {chartYTick[chart] ? (
                        <HorizontalGridLines
                          tickValues={[chartYTick[chart]]}
                          style={{ strokeDasharray: "2 2", stroke: "black" }}
                        />
                      ) : (
                        undefined
                      )}
                    </FlexibleWidthXYPlot>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <DiscreteColorLegend
                      items={chargingTimes.map(
                        ({ id, vehicle, charger }, i) => ({
                          title: (
                            <>
                              <span>
                                {vehicle.make} {vehicle.model}{" "}
                                {showTrims ? <>{vehicle.trim}</> : <></>}
                              </span>
                              <DimText> ({charger.shortname})</DimText>
                            </>
                          ),
                          color: palette[i % palette.length],
                          strokeWidth: selected === id ? 4 : 2
                        })
                      )}
                      onItemClick={(item, i) =>
                        setSelected(chargingTimes[i].id)
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>

          <Container maxWidth="md">
            <Box my={5}>
              <Card>
                <CardContent>
                  <Typography component="p" paragraph>
                    The assumption taken here is that an electric vehicle is
                    good enough for road trips when it does not require{" "}
                    <strong>more stops</strong> or <strong>longer stops</strong>{" "}
                    than what would naturally occur during a road trip. A common
                    recommendation is to take a break for 15 minutes every two
                    hours of driving, but everybody has their own preference.
                    Adding battery capacity or charging speed past this point
                    produces diminishing returns, only providing a benefit in
                    exceptional circumstances.
                  </Typography>
                  <Typography component="p" paragraph>
                    Electricity consumption is calculated using reference speed
                    of 110 km/h (68 mph) and assumes warm driving conditions.
                    Winter time consumption may be noticeably higher. The
                    consumption values for the cars are conservative estimates
                    so real world values are most likely better.
                  </Typography>
                  <Typography component="p">
                    Charging is calculated starting from 10% state of charge for
                    the battery, to account for normal usage of not completely
                    draining the battery in reaching the charger. Charging time
                    is calculated from charging curves for given chargers. Real
                    world charging times vary due to battery temperature,
                    battery state, other charger users, etc.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Container>
          <Faq>
            <Question title="But I need to drive 400 miles without stopping!">
              It is recommended to take a break every two hours of driving.
              However, you can modify the parameters above to see if there are
              suitable vehicles for you yet.
            </Question>
            <Question title="But I don't drive at 110 km/h!">
              Consumption data is most easily accessible for all the cars at 110
              km/h, which is why I am using it as a reference speed. Since the
              speed limit is not usually the average speed, it is likely that
              this consumption will match highway speed relatively well. If you
              normally drive significantly faster you can adjust the driving
              time selector half an hour shorter or half an hour longer if you
              are driving significantly slower to compensate for the difference.
            </Question>
            <Question title="But you are not listing several car models and trims!">
              Obtaining reliable charging curves is somewhat difficult. Also, I
              try to list only new cars that are available to be bought right
              now. If you have charging data for a car or charger that is not
              listed, please send it to me by email.
            </Question>
            <Question title="But my charging times do not match your data!">
              Obtaining reliable charging curves is somewhat difficult. Please
              take a video of your charging session, starting exactly from 10%
              SoC, under optimal conditions (battery warmed if necessary, or
              cool if necessary), charging to at least 90% SoC, and send to me
              by email. I will be very grateful!
            </Question>
            <Question title="But Hyundai Ioniq is already good enough for road trips!">
              Everybody has their own preference as to how often they stop and
              how much more they are willing to stop to drive an electric
              vehicle. My definition of good enough is that an electric vehicle
              should not slow down travel at all. However, you can modify the
              parameters above to make them suitable to you.
            </Question>
            <Question title="But Audi e-tron charges faster than a Tesla!">
              In the end, charging power in kilowatts, or charging energy in
              kilowatt hours, is irrelevant. What matters is how fast the car
              can be charged with enough range to reach the next stop, and this
              is a factor of charging power, energy consumption and charging
              curve.
            </Question>
            <Question title="But every road trip starts with a full battery!">
              This is often true. Starting with a full battery and being able to
              arrive at destination with nearly empty battery will result in
              fewer or shorter stops in practise. However, this aspect is better
              explored using{" "}
              <a href="https://abetterrouteplanner.com/">
                A Better Routeplanner
              </a>{" "}
              , which has many more variables to configure.
            </Question>
            <Question title="But there are no suitable chargers for my trip!">
              This is a charging network problem and a local problem. You can
              use{" "}
              <a href="https://abetterrouteplanner.com/">
                A Better Routeplanner
              </a>{" "}
              to plan your trip in more detail.
            </Question>
            <Question title="But there is no charger at the exact spot I need to stop at!">
              This is a charging network problem and a local problem. You can
              use{" "}
              <a href="https://abetterrouteplanner.com/">
                A Better Routeplanner
              </a>{" "}
              to plan your trip in more detail.
            </Question>
            <Question title="But the chargers in my area are not this fast!">
              This is a charging network problem and a local problem. You can
              use the charger selector to limit the listed chargers to what is
              available to you. Do note that there might not be charging data
              present for all chargers. You can also use{" "}
              <a href="https://abetterrouteplanner.com/">
                A Better Routeplanner
              </a>{" "}
              to plan your trip in more detail.
            </Question>
            <Question title="But I need to tow a trailer!">
              Towing a trailer is not reflected in the data above. Most likely
              you will need to stop more often with an electric vehicle, so an
              electric vehicle is not good enough yet for this usage. However,
              several electric pick-up trucks are coming and it is likely the
              situation will improve.
            </Question>
            {/*
            <Question title="But this site is really cool and I want to support you!">
              You can donate to me by sending Bitcoin to{" "}
              <a href="bitcoin:3Lq5biScdr3U3o37SzZNXUhRAPVzbrzVtU">
                3Lq5biScdr3U3o37SzZNXUhRAPVzbrzVtU
              </a>
              .
              Or, if your name is{" "}
              <a href="https://twitter.com/elonmusk">Elon Musk</a>, give me
              access to the development version of FSD (I have HW3, willing to
              sign NDA, email/login below).
            </Question>
            */}
          </Faq>
        </main>
        <footer>
          <Container maxWidth="md" align="center">
            <Divider />
            <Box mt={1} mb={2}>
              © Nuutti Kotivuori {new Date().getFullYear()},{" "}
              <Mailto email="naked@iki.fi" />
            </Box>
          </Container>
        </footer>
      </>
    </ThemeProvider>
  );
};

export default IndexPage;
