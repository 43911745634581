import chargingGraphs from "./chargingGraphs";

const reference_speed = 110; // km/h
const baseSoc = 10;
const mileInKm = 1.60934;

const vehicles = {
  "tesla-model-3-sr": {
    make: "Tesla",
    model: "Model 3",
    trim: "Standard Range Plus",
    description:
      "Tesla Model 3 Standard Range Plus (62kWh) with rear wheel drive, 18 inch tires and aero wheel caps.",
    wh_per_km: 160,
    battery: 62,
    chargers: {
      "sc-250": "tesla-model-3-sr-sc120kw.txt",
      "sc-120": "tesla-model-3-sr-sc120kw.txt",
      "fn-175": "tesla-model-3-sr-sc120kw.txt"
    }
  },
  "tesla-model-3-lr-rwd": {
    hide: true,
    make: "Tesla",
    model: "Model 3",
    trim: "Long Range RWD",
    description:
      "Tesla Model 3 Long Range (75kWh) with rear wheel drive, 18 inch tires and aero wheel caps.",
    wh_per_km: 160,
    battery: 75,
    chargers: {
      "sc-250": "tesla-model-3-sc250kw.txt",
      "sc-150": "tesla-model-3-150kw.txt",
      "sc-120": "tesla-model-3-120kw.txt",
      "fn-175": "tesla-model-3-175kw.txt",
      "fn-50": "tesla-model-3-50kw.txt"
    }
  },
  "tesla-model-3-lr": {
    make: "Tesla",
    model: "Model 3",
    trim: "Long Range",
    description:
      "Tesla Model 3 Long Range (75kWh) with all wheel drive and 18 inch tires.",
    wh_per_km: 170,
    battery: 75,
    chargers: {
      "sc-250": "tesla-model-3-sc250kw.txt",
      "sc-150": "tesla-model-3-150kw.txt",
      "sc-120": "tesla-model-3-120kw.txt",
      "fn-175": "tesla-model-3-175kw.txt",
      "fn-50": "tesla-model-3-50kw.txt"
    }
  },
  "tesla-model-3-p": {
    make: "Tesla",
    model: "Model 3",
    trim: "Performance",
    description:
      "Tesla Model 3 Long Range (75kWh) with performance all wheel drive and 20 inch tires",
    wh_per_km: 190,
    battery: 75,
    chargers: {
      "sc-250": "tesla-model-3-sc250kw.txt",
      "sc-150": "tesla-model-3-150kw.txt",
      "sc-120": "tesla-model-3-120kw.txt",
      "fn-175": "tesla-model-3-175kw.txt",
      "fn-50": "tesla-model-3-50kw.txt"
    }
  },
  "tesla-model-s-85": {
    hide: true,
    make: "Tesla",
    model: "Model S",
    trim: "85",
    description: "Tesla Model S 85 (2014)",
    wh_per_km: 185,
    battery: 85,
    chargers: {
      "sc-120": "tesla-model-s-85-120kw.txt"
    }
  },
  "tesla-model-s-sr": {
    make: "Tesla",
    model: "Model S",
    trim: "Standard Range",
    description: "Tesla Model S Standard Range (Raven)",
    wh_per_km: 185,
    battery: 75
  },
  "tesla-model-s-lr": {
    make: "Tesla",
    model: "Model S",
    trim: "Long Range",
    description: "Tesla Model S Long Range (Raven)",
    wh_per_km: 185,
    battery: 100,
    chargers: {
      "io-350": "tesla-model-sx-ionity-350kw.txt",
      "fn-175": "tesla-model-sx-lr-175kw.txt",
      "fn-50": "tesla-model-sx-lr-50kw.txt"
    }
  },
  "tesla-model-s-p": {
    make: "Tesla",
    model: "Model S",
    trim: "Performance",
    description: "Tesla Model S Performance (Raven)",
    wh_per_km: 190,
    battery: 100,
    chargers: {
      "io-350": "tesla-model-sx-ionity-350kw.txt",
      "fn-175": "tesla-model-sx-lr-175kw.txt",
      "fn-50": "tesla-model-sx-lr-50kw.txt"
    }
  },
  "tesla-model-x-sr": {
    make: "Tesla",
    model: "Model X",
    trim: "Standard Range",
    description: "Tesla Model X Standard Range (Raven)",
    wh_per_km: 220,
    battery: 75
  },
  "tesla-model-x-lr": {
    make: "Tesla",
    model: "Model X",
    trim: "Long Range",
    description: "Tesla Model X Long Range (Raven)",
    wh_per_km: 220,
    battery: 100,
    chargers: {
      "io-350": "tesla-model-sx-ionity-350kw.txt",
      "fn-175": "tesla-model-sx-lr-175kw.txt",
      "fn-50": "tesla-model-sx-lr-50kw.txt"
    }
  },
  "tesla-model-x-p": {
    make: "Tesla",
    model: "Model X",
    trim: "Performance",
    description: "Tesla Model X Performance (Raven)",
    wh_per_km: 230,
    battery: 100,
    chargers: {
      "io-350": "tesla-model-sx-ionity-350kw.txt",
      "fn-175": "tesla-model-sx-lr-175kw.txt",
      "fn-50": "tesla-model-sx-lr-50kw.txt"
    }
  },
  "audi-e-tron": {
    make: "Audi",
    model: "e-tron",
    trim: "",
    description: "Audi e-tron 55 Quattro (2018)",
    wh_per_km: 250,
    battery: 95,
    chargers: {
      "io-350": "etron-350kw.txt",
      "fn-175": "etron-175kw.txt",
      "fn-50": "etron-50kw.txt"
    }
  },
  "bmw-i3-60": {
    make: "BMW",
    model: "i3",
    trim: "60 Ah",
    description: "BMW i3 60 Ah",
    wh_per_km: 170,
    battery: 22
  },
  "bmw-i3-94": {
    make: "BMW",
    model: "i3",
    trim: "94 Ah",
    description: "BMW i3 94 Ah",
    wh_per_km: 170,
    battery: 33
  },
  "bmw-i3-120": {
    make: "BMW",
    model: "i3",
    trim: "120 Ah",
    description: "BMW i3 120 Ah",
    wh_per_km: 170,
    battery: 42.2,
    chargers: {
      "fn-175": "bmw-i3-44kwh.txt",
      "fn-50": "bmw-i3-44kwh.txt"
    }
  },
  "chevrolet-bolt": {
    make: "Chevrolet",
    model: "Bolt",
    trim: "",
    description: "Chevrolet Bolt",
    wh_per_km: 184,
    battery: 60,
    chargers: {
      "fn-175": "ampera-e.txt",
      "fn-50": "ampera-e.txt"
    }
  },
  "hyundai-ioniq": {
    make: "Hyundai",
    model: "Ioniq",
    trim: "",
    description: "Hyundai Ioniq",
    wh_per_km: 144,
    battery: 28,
    chargers: {
      "fn-175": "ioniq-100kw.txt", // should be ioniq-175kw, but we'll simplify
      //"de-100": "ioniq-100kw.txt",
      "fn-50": "ioniq-50kw.txt"
    }
  },
  "hyundai-kona-39": {
    make: "Hyundai",
    model: "Kona",
    trim: "39 kWh",
    description: "Hyundai Kona 39 kWh",
    wh_per_km: 176,
    battery: 39
  },
  "hyundai-kona-64": {
    make: "Hyundai",
    model: "Kona",
    trim: "64 kWh",
    description: "Hyundai Kona 64 kWh",
    wh_per_km: 176,
    battery: 64,
    chargers: {
      "fn-175": "kona-175kw.txt",
      "fn-50": "kona-50kw.txt"
    }
  },
  "jaguar-i-pace": {
    make: "Jaguar",
    model: "I-PACE",
    trim: "",
    description: "Jaguar I-PACE",
    wh_per_km: 271,
    battery: 90,
    chargers: {
      "fn-175": "i-pace-175kw.txt",
      "fn-50": "i-pace-50kw.txt"
    }
  },
  "kia-niro-39": {
    make: "Kia",
    model: "e-Niro",
    trim: "39 kWh",
    description: "Kia e-Niro 39 kWh",
    wh_per_km: 179,
    battery: 39
  },
  "kia-niro-64": {
    make: "Kia",
    model: "e-Niro",
    trim: "64 kWh",
    description: "Kia e-Niro 64 kWh",
    wh_per_km: 179,
    battery: 64,
    chargers: {
      "fn-175": "niro-175kw.txt",
      "fn-50": "niro-50kw.txt"
    }
  },
  "kia-soul-ev": {
    make: "Kia",
    model: "Soul EV",
    trim: "",
    description: "Kia Soul EV 64 kWh",
    wh_per_km: 210,
    battery: 64
  },
  "mercedes-benz-eqc": {
    make: "Mercedes-Benz",
    model: "EQC",
    trim: "",
    description: "Mercedes-Benz EQC",
    wh_per_km: 244,
    battery: 80,
    chargers: {
      "fn-175": "mercedes-eqc-175kw.txt",
      "fn-50": "mercedes-eqc-50kw.txt"
    }
  },
  "nissan-leaf-24": {
    make: "Nissan",
    model: "Leaf",
    trim: "24 kWh",
    description: "Nissan Leaf 24 kWh",
    wh_per_km: 194,
    battery: 24
  },
  "nissan-leaf-30": {
    make: "Nissan",
    model: "Leaf",
    trim: "30 kWh",
    description: "Nissan Leaf 30 kWh",
    wh_per_km: 194,
    battery: 30
  },
  "nissan-leaf-40": {
    make: "Nissan",
    model: "Leaf",
    trim: "40 kWh",
    description: "Nissan Leaf 40 kWh",
    wh_per_km: 180,
    battery: 40,
    chargers: {
      "fn-175": "nissan-leaf-40kwh.txt",
      "fn-50": "nissan-leaf-40kwh.txt"
    }
  },
  "nissan-leaf-plus-62": {
    make: "Nissan",
    model: "Leaf",
    trim: "Plus 62 kWh",
    description: "Nissan Leaf Plus 62 kWh",
    wh_per_km: 180,
    battery: 62
  },
  "renault-zoe-22": {
    make: "Renault",
    model: "Zoe",
    trim: "22 kWh",
    description: "Renault Zoe 22 kWh",
    wh_per_km: 210,
    battery: 22
  },
  "renault-zoe-41": {
    make: "Renault",
    model: "Zoe",
    trim: "41 kWh",
    description: "Renault Zoe 41 kWh",
    wh_per_km: 210,
    battery: 41
  }
};
for (const k in vehicles) vehicles[k].id = k;

const chargers = {
  "fn-50": {
    shortname: "FN 50kW",
    name: "Fastned 50 kW",
    description:
      "Fastned operated charging stations with maximum DC output of 50 kW"
  },
  "fn-175": {
    shortname: "FN 175kW",
    name: "Fastned 175 kW",
    description:
      "Fastned operated charging stations with maximum DC output of 175 kW."
  },
  // "de-100": {
  //   shortname: "De 100kW",
  //   name: "Delta 100 kW",
  //   description:
  //     "Delta operated charging stations with maximum DC output of 100 kW."
  // },
  "io-350": {
    shortname: "Io 350kW",
    name: "Ionity 350 kW",
    description:
      "Ionity operated charging stations with maximum DC output of 350 kW."
  },
  "sc-120": {
    shortname: "SC 120kW",
    name: "SuperCharger 120 kW",
    description:
      "Tesla Superchargers with maximum DC output of 120 kW (V2). Only usable by Tesla vehicles."
  },
  "sc-150": {
    shortname: "SC 150kW",
    name: "SuperCharger 150 kW",
    description:
      "Tesla Superchargers with maximum DC output of 150 kW (V2). Only usable by Tesla vehicles."
  },
  "sc-250": {
    shortname: "SC 250kW",
    name: "SuperCharger 250 kW",
    description:
      "Tesla Superchargers with maximum DC output of 250 kW (V3). Only usable by Tesla vehicles."
  }
};
for (const k in chargers) chargers[k].id = k;

function interpolate(points, x) {
  let i;
  for (i = 0; i < points.length; i++) {
    if (points[i][0] > x) break;
  }
  let prev = points[i - 1];
  let next = points[i];
  if (prev === undefined || next === undefined) {
    return undefined;
  }
  if (next[0] === x) return next[1];
  let val =
    prev[1] + (next[1] - prev[1]) * ((x - prev[0]) / (next[0] - prev[0]));
  //console.log(prev[0], x, next[0], prev[1], val, next[1]);
  return val;
}

export function calcChargingTime(vehicle, charger, distance) {
  const vehicleData = vehicles[vehicle];
  const whPerKm = vehicleData.wh_per_km;
  const whNeeded = whPerKm * distance;
  const battery = vehicleData.battery;
  const curvePoints = chargingGraphs[vehicleData.chargers[charger]];
  if (!curvePoints)
    throw new Error(
      `cannot find curve points for graph ${vehicleData.chargers[charger]}`
    );
  const kwPoints = [];
  const kwhPoints = [];
  const kmPoints = [];
  const milePoints = [];
  const socPoints = [];
  let minutes = 0,
    wh = 0,
    chargingTime = Infinity;

  while (true) {
    const soc = (wh / 1000 / battery) * 100 + baseSoc;
    if (soc >= 100) break;
    const kw = interpolate(curvePoints, soc);
    if (kw === undefined) break;
    wh += (kw * 1000) / 60 / 4;
    minutes += 0.25;
    if (chargingTime === Infinity && wh >= whNeeded) chargingTime = minutes;
    kwPoints.push({ x: minutes, y: kw });
    kwhPoints.push({ x: minutes, y: wh / 1000 });
    kmPoints.push({ x: minutes, y: wh / whPerKm });
    milePoints.push({ x: minutes, y: wh / whPerKm / mileInKm });
    socPoints.push({ x: minutes, y: soc });
  }
  return { chargingTime, kwPoints, kwhPoints, kmPoints, milePoints, socPoints };
}

export function calcChargingTimes({ stopTime, travelTime }) {
  const distance = (reference_speed * travelTime) / 60;
  const chargingTimes = [];

  for (const vehicle in vehicles) {
    for (const charger in vehicles[vehicle].chargers) {
      const {
        chargingTime,
        kwPoints,
        kwhPoints,
        kmPoints,
        milePoints,
        socPoints
      } = calcChargingTime(vehicle, charger, distance);
      chargingTimes.push({
        id: `${vehicles[vehicle].id}.${chargers[charger].id}`,
        vehicle: vehicles[vehicle],
        charger: chargers[charger],
        chargingTime,
        kwPoints,
        kwhPoints,
        kmPoints,
        milePoints,
        socPoints,
        good: chargingTime <= stopTime,
        impossible: chargingTime === Infinity
      });
    }
  }
  chargingTimes.sort((a, b) => a.chargingTime - b.chargingTime);
  return chargingTimes;
}

export function getChargerList() {
  return Object.values(chargers);
}
